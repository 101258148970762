"use strict";

import {findAll, findIn, addClass, removeClass} from "@elements/dom-utils"
import {onFind} from "@elements/init-modules-in-scope";
import {registerPermissionGrantedCallback} from "@elements/cookie-permissions";


export function init() {
    registerPermissionGrantedCallback('externalVideo', function () {
        onFind('.js-external-video', function () {
            findAll('.js-external-video').forEach(videoElement => {
                addClass('d-none', findIn('.js-accept-cookie-service', videoElement).parentElement);

                let iframe = findIn('.embed-responsive-item', videoElement);
                removeClass('bg-light-grey', videoElement);

                iframe.src = iframe.dataset.iframeSrc;
            });
        });
    });
}
